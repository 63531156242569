<div>
  <!-- Info modal -->
  <div class="modal" v-show="showModal">
    <div class="modal-bg" @click="toggleModal()"></div>
    <div class="smart-specs-modal">
      <!-- Close button -->
      <div class="close-modal" @click="toggleModal()">
        <div class="cross-1"></div>
        <div class="cross-2"></div>
      </div>
      <div class="info-container" style="background-color: transparent;">
        <div class="info-flex">
          <div class="header">Our Accounts</div>
          <div class="banners">
            <div class="banner">
              <div class="title">Location</div>
              <div class="subtitle">Free</div>
              <div class="info">Best for businesses that need a simple and easy-to-use solution for tracking in near real time the position of their assets/cargo</div>
              <div class="details"><strong>Map:</strong> Location of the assets/shipments.</div>
              <div class="features">Platform features</div>
              <div class="feature-item">
                <div class="icon">
                  <i class="map-icon"></i>
                </div>
               <span class="text">Map (location only)</span>
              </div>
            </div>
            <div class="banner">
              <div class="title">Basic</div>
              <div class="subtitle">€4,00<span> User/month</span><br>
                <span>(annual commitment)</span>
              </div>
              
              <div class="info">Best for businesses that need simple and easy to use monitoring procedures without time consuming installation and complex software</div>
              <div class="details"><strong>Location account, plus:</strong><br/>
                <strong>Monitoring:</strong> Current environmental conditions (temperature/ humidity) of each asset during transportation.
                </div>
              <div class="features">Platform features</div>
              <div class="feature-item">
                <div class="icon">
                  <i class="map-icon"></i>
                </div>
               <span class="text">Map</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="monitoring-icon"></i>
                </div>
               <span class="text">Monitoring<br/>(live data)</span>
              </div>
            </div>
            <div class="banner">
              <div class="title">Standard</div>
              <div class="subtitle">€8,00<span> User/month</span><br>
                <span>(annual commitment)</span>
              </div>
              <div class="info">Best for businesses that need simple monitoring and effective management of their cargo / asset with crucial notifications and alerts</div>
              <div class="details"><strong>Basic account, plus:</strong><br/>
                <strong>Access to historical data.</strong><br/>
                <strong>Calendar:</strong> Shipments schedule (completed, in transit, scheduled).<br/> 
                <strong>Management:</strong> A user-friendly tool to manage all assets/cargo and to configure the devices. 
                </div>
              <div class="features">Platform features</div>
              <div class="feature-item">
                <div class="icon">
                  <i class="map-icon"></i>
                </div>
               <span class="text">Map</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="monitoring-icon"></i>
                </div>
               <span class="text">Monitoring<br/>(live + historical data)</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="calendar-icon"></i>
                </div>
               <span class="text">Calendar</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="management-icon"></i>
                </div>
               <span class="text">Management</span>
              </div>
            </div>
            <div class="banner">
              <div class="title">Advanced</div>
              <div class="subtitle">€16,00<span> User/month</span><br>
                <span>(annual commitment)</span>
              </div>
              <div class="info">Best for businesses that need advanced cargo monitoring procedures, effective notifications and efficient insights for business optimization</div>
              <div class="details"><strong>Standard account, plus:</strong><br/>
                <strong>Reports:</strong> Excel file download of all events and readings that occurred during a trip (completed or in transit) or over a period of time.<br/> 
                <strong>Share-link:</strong> Share data of with other stakeholders (partners, employees, customers and others). 
                </div>
              <div class="features">Platform features</div>
              <div class="feature-item">
                <div class="icon">
                  <i class="map-icon"></i>
                </div>
               <span class="text">Map</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="monitoring-icon"></i>
                </div>
               <span class="text">Monitoring<br/>(live + historical data)</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="calendar-icon"></i>
                </div>
               <span class="text">Calendar</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="management-icon"></i>
                </div>
               <span class="text">Management + Share-link (+2€/share)</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="reports-icon"></i>
                </div>
               <span class="text">Reports</span>
              </div>
            </div>
            <div class="banner">
              <div class="title">Premium</div>
              <div class="subtitle">€36,00<span> User/month</span><br>
                <span>(annual commitment)</span>
              </div>
              <div class="info">Best for businesses that need have full control of their cargo quality and integrity, simple monitoring procedures, full interaction with all stakeholders and advanced analytics</div>
              <div class="details"><strong>Advanced account, plus:</strong><br/>
                <strong>KPIs:</strong> Customized analytics about the overall performance and the pain-points of the entire supply chain. 
                </div>
              <div class="features">Platform features</div>
              <div class="feature-item">
                <div class="icon">
                  <i class="map-icon"></i>
                </div>
               <span class="text">Map</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="monitoring-icon"></i>
                </div>
               <span class="text">Monitoring<br/>(live + historical data)</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="calendar-icon"></i>
                </div>
               <span class="text">Calendar</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="management-icon"></i>
                </div>
               <span class="text">Management + Share-link (+2€/share)</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="reports-icon"></i>
                </div>
               <span class="text">Reports</span>
              </div>
              <div class="feature-item">
                <div class="icon">
                  <i class="kpis-icon"></i>
                </div>
                <span class="text">KPIs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Desktop designs -->
  <div class="main desktop-flex">
    <div class="col-1">
      <div class="row-1">

        <div class="model-info-wrapper">
          <video autoplay muted loop playsinline class="video-hero">
            <source src="../../../assets/media/videos/supply-chain/request-quote-form-platform.mp4" type="video/mp4" />
          </video>
        </div>

      </div>
    </div>
    <div class="col-2">
      <div class="controls-section">
        <div class="section type">
          <div class="header">Configure your platform plan</div>
          <div class="desc">Choose the platform plan that best suits your needs.</div>
          <div class="form">
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <!--<input type="radio" id="basic" name="plan" value="basic" v-model="form.platform.type">-->
                  <label :class="{active: form.platform.location.users > 0 }" for="location">
                    <div class="header">Location</div>
                    <div class="desc">For businesses needing easy-to-use and straightforward monitoring without complex installation and software</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.platform.location.users" placeholder="No of users" required>
                </div>              
              </div>
            </div>
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <!--<input type="radio" id="basic" name="plan" value="basic" v-model="form.platform.type">-->
                  <label :class="{active: form.platform.basic.users > 0 }" for="basic">
                    <div class="header">Basic</div>
                    <div class="desc">For businesses needing easy-to-use and straightforward monitoring without complex installation and software</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.platform.basic.users" placeholder="No of users" required>
                </div>              
              </div>
            </div>
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <!--<input type="radio" id="standard" name="plan" value="standard" v-model="form.platform.type">-->
                  <label :class="{active: form.platform.standard.users > 0 }" for="standard">
                    <div class="header">Standard</div>
                    <div class="desc">For businesses needing simple monitoring and effective management of cargo/assets with vital notifications and alerts.</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.platform.standard.users" placeholder="No of users" required>
                </div>
              </div>
              
            </div>
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <!--<input type="radio" id="advanced" name="plan" value="advanced" v-model="form.platform.type">-->
                  <label :class="{active: form.platform.advanced.users > 0 }" for="advanced">
                    <div class="header">Advanced</div>
                    <div class="desc">For businesses needing advanced cargo/assets monitoring, effective notifications, efficient insights and optimization of their business.</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.platform.advanced.users" placeholder="No of users" required>
                </div>
              </div>
              
            </div>
            <div class="form-control">
              <div class="form-container">
                <div class="form-buttons">
                  <!--<input type="radio" id="premium" name="plan" value="premium" v-model="form.platform.type">-->
                  <label :class="{active: form.platform.premium.users > 0 }" for="premium">
                    <div class="header">Premium</div>
                    <div class="desc">For businesses needing complete control of their cargo/asset quality and integrity, and easy monitoring with advanced analytics and entire interaction with stakeholders.</div>
                  </label>
                </div>
                <div class="input form-control">
                  <input min="1" type="number" v-model="form.platform.premium.users" placeholder="No of users" required>
                </div>
              </div>
            </div>
            <div class="form-control">
              <button class="btn btn-primary" @click="toggleModal()">Learn more</button>
            </div>
          </div>
        </div>
      </div>
      <div class="button-section">
        <button @click="$emit('changeStep', 'request-quote')" class="btn-secondary">Next</button>
      </div>
    </div>
  </div>

  <!-- Mobile design -->
  <div class="mobile">
    <div class="container">

      <div class="header">Configure your platform plan</div>
      <div class="model-info-wrapper">
        <video autoplay muted loop playsinline class="video-hero">
          <source src="../../../assets/media/videos/supply-chain/request-quote-form-platform.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>Basic</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.platform.basic.users" placeholder="No of users" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>Standard</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.platform.standard.users" placeholder="No of users" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>Advanced</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.platform.advanced.users" placeholder="No of users" required>
        </div>
      </div>
      <div class="option-item">
        <div class="form-control">
          <span>Premium</span>
        </div>
        <div class="input form-control">
          <input min="1" type="number" v-model="form.platform.premium.users" placeholder="No of users" required>
        </div>
      </div>
      <div v-for="option in package">
        <div class="description">
          <div class="description-title">
            {{ option.title }}
          </div>
          <div class="description-body">
            {{ option.body }}
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</div>